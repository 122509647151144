import { devices } from 'shared/styles/components';
import styled from 'styled-components';

export const Libum404Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`;

export const Libum404Row = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--page-grid-cols), 1fr);
  gap: 0 20px;
  padding: var(--page-gutter-padding);
  width: 100%;
  max-width: var(--page-max-width);
  box-sizing: border-box;
`;

export const Libum404Body = styled.div`
  margin-top: 20px;
  max-width: 600px;
  grid-column: span 7;
  overflow: auto;

  h1 {
    margin: 0;
  }

  .text-large {
    margin-top: 20px;
  }

  @media ${devices.mobileMax} {
    h1 {
      font-size: 32px;
    }
    .text-large {
      font-size: 18px;
    }
  }
`;

export const Libum404Links = styled.ul`
  margin-top: 20px;
  padding: 0;

  a {
    position: relative;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 20px;
    font-weight: 600;
    line-height: 35px;
    text-decoration: none;
    transition: 0.1s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.textTertiary};
    }
  }
`;

export const Libum404ImageContainer = styled.div`
  position: relative;
  display: block;
  padding: 20px;
  grid-column: span 5;
  max-width: 350px;

  @media ${devices.tabletMax} {
    order: -1;
  }
`;
