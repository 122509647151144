import Container from 'components/container';
import Link from 'next/link';
import Script from 'next/script';
import { LIBUM_FEEDBACK, LIBUM_STATUS, LIBUM_URL } from 'shared/constants/globals';
import { Libum404Body, Libum404Container, Libum404ImageContainer, Libum404Links, Libum404Row } from './404.styles';

export default function Libum404() {
  const title = 'Page Not Found | Libum';
  const url = `${LIBUM_URL}/404`;

  return (
    <Container title={title} description="" keywords="" url={url} socialCard="libum">
      <Libum404Container>
        <Libum404Row>
          <Libum404Body>
            <div>
              <h1>Lost in the snow?</h1>
              <div className="text-large">
                That&apos;s okay, friend. At least the Explorer Dude is here to keep you company. Go ahead and give him
                a wave. Did you do it? Good now he&apos;s happy - here are some links that we know work so you can be
                happy too:
              </div>
              <Libum404Links>
                <li>
                  <Link href="/">Home</Link>
                </li>
                <li>
                  <Link href={LIBUM_STATUS}>Status</Link>
                </li>
                <li>
                  <Link href={LIBUM_FEEDBACK}>Feedback</Link>
                </li>
              </Libum404Links>
            </div>
          </Libum404Body>
          <Libum404ImageContainer>
            <Script src="/scripts/explorer.dude.js" strategy="lazyOnload" />
            <div>
              <div id="explorer-dude" style={{ width: '350px' }} />
            </div>
          </Libum404ImageContainer>
        </Libum404Row>
      </Libum404Container>
    </Container>
  );
}
